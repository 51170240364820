import { Link, useNavigate } from "react-router-dom";
import classes from "../modules/Header.module.css";
import { BiCross, BiMenu } from "react-icons/bi";
import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

function Header() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const dropdownClasses = `${classes.dropdown} ${isOpen ? classes.open : ""}`;
  const Icon = isOpen ? BiCross : BiMenu;

  return (
    <header className={classes.header}>
       <div>
                  <h1 className={classes.logotext}>
                    StudBroad Overseas Education
                  </h1>
                </div>

      <nav className={classes.navigation}>
        <ul className={classes.list}>
          <li>
            <ScrollLink
              className={classes.links}
              to="home"
              smooth={true}
              duration={500}
            >
              Home
            </ScrollLink>
            <ScrollLink
              className={classes.links}
              to="Country"
              smooth={true}
              duration={500}
            >
              Countries
            </ScrollLink>
            <ScrollLink
              className={classes.links}
              to="University"
              smooth={true}
              duration={500}
            >
              Universities
            </ScrollLink>
            <ScrollLink
              className={classes.links}
              to="contactUs"
              smooth={true}
              duration={500}
            >
              Contact Us
            </ScrollLink>
        
            
           
          </li>
        </ul>
      </nav>

     
      <div className={classes.menuicon} onClick={handleDropdownClick}>
        <Icon />
      </div>

      {isOpen && (
        <div className={dropdownClasses}>
          <ul>
            <li>
            <ScrollLink
              className={classes.links}
              to="home"
              smooth={true}
              duration={500}
            >
              Home
            </ScrollLink>
            </li>
            <li>
            <ScrollLink
              className={classes.links}
              to="Country"
              smooth={true}
              duration={500}
            >
              Countries
            </ScrollLink>
            </li>
       <li>
       <ScrollLink
              className={classes.links}
              to="University"
              smooth={true}
              duration={500}
            >
              Universities
            </ScrollLink>
       </li>
          
            <li>
            <ScrollLink
              className={classes.links}
              to="contactUs"
              smooth={true}
              duration={500}
            >
              Contact Us
            </ScrollLink>
            </li>
          
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;
