const TimeLineInfo = [
    {
      id : 1,
      title: "Profile assessments and consultation",
      desc : "Our team of experienced advisors provides guidance and support to help you achieve your academic and career goals. ",
      cardbg: "rgba(26, 182, 157, 0.07)",
      Image: "/greenico.svg",
    },
    {
        id : 2,
        title: "Cherry picking the universities",
        desc : "We helps you to select the preferences, goals, and factors like program, location, and career opportunities.",
        cardbg: "rgba(238, 74, 98, 0.07)",
        Image: "/greenico.svg",
        },
    {
      id:3,
      title: "Guaranteed scholarships",
      desc : "We offers guaranteed scholarships up to one lakh to eligible students to help them achieve their academic goals.",
      cardbg: "rgba(70, 100, 228, 0.07)",
      Image: "/greenico.svg", },
    {
      id:4,
      title: "Guidance on affordable fee structure",
      desc : "We can help you to find the finanacial aid that is required at any point of process",
      cardbg: "rgba(248, 148, 31, 0.07)",
      Image: "/greenico.svg",  },
      {
      id : 5,
      title: "IELTS Preperations",
      desc : "We streamlines admission procedures for students seeking quick and efficient application processing.",
      cardbg: "rgba(26, 182, 157, 0.07)",
      Image: "/greenico.svg",   },
    {
        id : 6,
        title: "Worry free interview training",
        desc : "We offers expert interview training services to help you prepare for academic interviews and improve your chances of success. ",
        cardbg: "rgba(238, 74, 98, 0.07)",
        Image: "/greenico.svg", },
    {
      id:7,
      title: "Visa documentation & filing",
      desc : "We can help you to complete all visa documentation and its filings. You can reach out to us any point of time. ",
      cardbg: "rgba(70, 100, 228, 0.07)",
      Image: "/greenico.svg",    },
    {
      id:8,
      title: "Post landing services",
      desc : "We provides comprehensive post-landing services, including assistance for accommodation and part time jobs",
      cardbg: "rgba(248, 148, 31, 0.07)",
      Image: "/greenico.svg",    }
  ];
  
  export default TimeLineInfo