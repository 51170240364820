import React from 'react';
import classes from  "../modules/Gallery.module.css";


const Gallery = ({imagesArray}) => {
  return (
    <div className={classes.gallerycontainer}>
      <h1 className={classes.gallerytitle}>Instagram Gallery</h1>
      <div className={classes.imagegrid}>
        {imagesArray.map((image, index) => (
          <div key={index} className={classes.imagecontainer}>
            <img
              src={image}
              alt={`Image ${index}`}
              className={classes.galleryimage}
              
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
