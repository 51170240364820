import classes from "../modules/AboutUs.module.css";
import TruncatedParagraph from "../ui/TrucatedParagraph";

function AboutUs() {
  const aboutUsText ="Studbroad is a one stop solution where you will have an idea on where and what to acquire from us to become yourself a wholesome individual.We educate students and parents on the extensive career and personal benefits of studying in six english speaking countries: The UK, US, Canada, Australia, New Zealand and Ireland"
  return (
    <div id="aboutUs" className={classes.container}>
     
     <div className={classes.leftcontainer}>
        <img
          src="/assets/373.jpg"
          alt="Aboutus"
          className={classes.image}
        />
      </div>

      <div className={classes.rightcontainer}>
        <div className={classes.textcontainer}>
          <p className={classes.desc}>What we do?</p>
          <h1 className={classes.title}>
           Pushing Boundaries, Growing 
            <span className={classes.spanedtext}> Worldwide.</span><br></br> Our Journey of Global Expansion<br></br>
          </h1>
          <TruncatedParagraph className={classes.desc} text={aboutUsText} maxWords={60} />
        
        </div>
      </div>
      
    </div>
  );
}

export default AboutUs;
