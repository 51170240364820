import { Link } from "react-router-dom";
import classes from "../modules/Footer.module.css";
import { Link as ScrollLink } from "react-scroll";

function Footer() {
  return (
    <>
      <div id="contactUs" className={classes.footer}>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.footerCol}>
              <div className={classes.imagesection}>
                <div>
                  <h1 className={classes.logotext}>
                    StudBroad Overseas Education
                  </h1>
                </div>
              </div>
            </div>
            <div className={classes.footerCol}>
              <h4>About Us</h4>
              <ul>
                <li>
                  <li>
                    <ScrollLink
                      className={classes.links}
                      to="ourProcess"
                      smooth={true}
                      duration={500}
                    >
                      Our Process
                    </ScrollLink>
                  </li>
                </li>
                <li>
                  <ScrollLink
                    className={classes.links}
                    to="aboutUs"
                    smooth={true}
                    duration={500}
                  >
                    Who We Are?
                  </ScrollLink>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className={classes.footerCol}>
              <h4>Contact us</h4>
              <ul>
                <li>
                  <Link to="https://wa.me/9746997149">Chat Support</Link>
                </li>
                <li>
                  <Link to="tel:+919746997149">Call us</Link>
                </li>
                <li>
                  <Link to="mailto:hello@studbroad.com">email us</Link>
                </li>
              </ul>
            </div>
            <div className={classes.footerCol}>
              <h4>Our Presence</h4>
              <ul>
                <li>
                  <Link to="">Opp. Cafe Coffee Day,</Link>
                </li>
                <li>
                  <Link to="">Kaloor- Kadavanthra Road</Link>
                </li>
                <li>
                  <Link to="">Ernakulam</Link>
                </li>
                <li>
                  <Link to="">Kerala 682017</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.powered}>
        <h3>
          Powered by <span>Teqbae </span> Innovations & Technologies
        </h3>
      </div>
    </>
  );
}

export default Footer;
