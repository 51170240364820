import { Link } from 'react-router-dom';
import classes from '../modules/Hero.module.css';
import { scroller } from 'react-scroll';

export default function HeroArea() {

  const onExploreMoreClicked = () => {
    scroller.scrollTo('targetElement', {
      duration: 500, // Scroll duration in milliseconds
      smooth: 'easeInOutQuint', // Easing function for smooth scrolling
    });
  };

  return (
    <div>
      <div className={classes.herobox}>
        <div className={classes.herodetails}>
          <h1 className={classes.heroheading}>Explore Boundless Opportunities Abroad. Your <span className={classes.spanedtext}>Gateway to Global Education!</span><br></br></h1>
          <p  className={classes.herodesc}>With the aid of our highly skilled panel of advisors, navigating the path to your dream university becomes an effortless and well-guided journey. Benefit from their expertise as they skillfully assist you in securing your place at the university you've always aspired to join.</p>
          <div  className={classes.btnbox}>
            <Link href="#"  className={classes.ctabtn} onClick={onExploreMoreClicked}>
              Explore more
            </Link>
      
          </div>
        </div>
        <div  className={classes.heroimage} >
          <img src="/assets/himage.png" alt="studbroad" />
        </div>
      </div>
    </div>
  );
}

