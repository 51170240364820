import classes from "../modules/EnquiryForm.module.css";
import { TextField, Button, Stack, Chip } from "@mui/material";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Element } from "react-scroll";
import { useMutation } from "react-query";
import { submitEnquiry } from "../apis/ApiServices";
import enquiryRequestModel from "../models/EnquiryRequestModel";
import toast, { Toaster } from "react-hot-toast";

function EnquiryForm() {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setphoneNumberError] = useState(false);

  // const mutation = useMutation((enquiryInfo) => {
  //   submitEnquiry(enquiryInfo);
  // });

  const mutation = useMutation(submitEnquiry, {
    // ... (other options)

    // Pass enquiryData as an argument to mutation.mutate
    onMutate: (enquiryInfo) => {
      // Optionally, you can perform actions before the mutation
      // For example, you can show a loading state here.
      // submitEnquiry(enquiryInfo)
    },
    onSuccess: (data, variables, context) => {
      // Access the response data here (data)

      toast.success(data.responsedescription);
      resetFields();
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    setNameError(false);
    setEmailError(false);
    setphoneNumberError(false);

    if (name === "") {
      setNameError(true);
    }
    if (email === "") {
      setEmailError(true);
    }
    if (phoneNumber === "") {
      setphoneNumberError(true);
    }

    if (name && email && phoneNumber) {
      const enquiryData = enquiryRequestModel(name, email, phoneNumber, 1);
      // mutation.mutate({ enquiryData });
      mutation.mutate(enquiryData);
    }
  };
  const resetFields = () => {
    // Clear the form fields after a successful submission
    setName("");
    setEmail("");
    setPhoneNumber("");
    setSelectedChips([]); // Clear selected chips
    mutation.reset(); // Reset the mutation state
  };
  const [selectedChips, setSelectedChips] = useState([]); // State to keep track of selected chips

  const handleChipClick = (label) => {
    if (selectedChips.includes(label)) {
      // Chip is already selected, so remove it from the selectedChips array
      setSelectedChips(selectedChips.filter((chip) => chip !== label));
    } else {
      // Chip is not selected, so add it to the selectedChips array
      setSelectedChips([...selectedChips, label]);
    }
  };

  return (
    <Element name="targetElement">
      <div id="enquiry" className={classes.container}>
        <div className={classes.rightcontainer}>
          <div className={classes.textcontainer}>
            <p className={classes.desc}>Any specific question to ask?</p>
            <h1 className={classes.title}>
              Submit an
              <span className={classes.spanedtext}> enquiry</span> now !
              
            </h1>
            <div className={classes.formdata}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  variant="outlined"
                  color="secondary"
                  type="name"
                  sx={{ mb: 1 }}
                  fullWidth
                  value={name}
                  error={nameError}
                />
                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                  <TextField
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={email}
                    error={emailError}
                  />
                  <TextField
                    label="Mobile"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="phone"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={phoneNumber}
                    error={phoneNumberError}
                  />
                </Stack>

                <Button
                  className={classes.submitbtn}
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={mutation.isLoading}
                >
                  {mutation.isLoading ? "In Progress..." : "Enquire Now"}
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div className={classes.leftcontainer}>
          <img src="/assets/qa.jpg" alt="Aboutus" className={classes.image} />
        </div>

        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </Element>
  );
}

export default EnquiryForm;
