import Header from "../components/Header";
import HeroArea from "../components/HeroArea";

import Footer from "../components/Footer";
import EnquiryForm from "../components/EnquiryForm";
import TimeLine from "../components/TimeLine";
import AboutUs from "../components/AboutUs";
import TopCountries from "../components/TopCountries";
import Gallery from "../components/Gallery";

const images = [
  '/insta/1.png',
  '/insta/2.jpg',
  '/insta/3.jpg',
  '/insta/4.jpg',
  '/insta/5.jpg',
  '/insta/6.jpg',
  '/insta/7.jpg',
  '/insta/8.jpg',
  '/insta/9.jpeg',
  '/insta/10.jpeg',

  // Add more image paths as needed
];

function LayoutCard(props) {
  return (
    <div>
      <Header />
      <HeroArea />
     <TimeLine />
     <TopCountries />
     <AboutUs />
     <Gallery imagesArray={images}/>
      <EnquiryForm />
      <Footer />
     
      {props.children}
    </div>
  );
}

export default LayoutCard;
