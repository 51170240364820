import classes from "../modules/TimeLine.module.css";
import TimeLineInfo from "../models/TimeLineData";
import CardView from "../ui/CardView";

function TimeLine() {
  return (
    <div id="ourProcess" className={classes.container}> 
    <div className={classes.innertopcontainer}>
      <div >
        <p>Why Us?</p>
        <h1>
            Different <span className={classes.highlite}>phases</span> of our care.
          </h1>
     
       
      </div>

    
    </div>
    <div className={classes.innercontainer}>
     <ul className={classes.listcontainer}>
        { TimeLineInfo.map((timeLineData) => (
          <CardView 
          title={timeLineData.title} 
          body={timeLineData.desc} 
          bg={timeLineData.cardbg} 
          img = {timeLineData.Image}
          />
        ))}
        </ul>
     </div>
    </div>
  );
}

export default TimeLine;
